
@font-face {
  font-family: 'NanumGothic';
  src: url('/fonts/subset-NanumGothicExtraBold.woff2') format('woff2'),
      url('/fonts/subset-NanumGothicExtraBold.woff') format('woff'),
      url('/fonts/subset-NanumGothicExtraBold.ttf') format('truetype');
  font-weight: bolder;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NanumGothic';
  src: url('/fonts/subset-NanumGothic.woff2') format('woff2'),
      url('/fonts/subset-NanumGothic.woff') format('woff'),
      url('/fonts/subset-NanumGothic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NanumGothic';
  src: url('/fonts/subset-NanumGothicBold.woff2') format('woff2'),
      url('/fonts/subset-NanumGothicBold.woff') format('woff'),
      url('/fonts/subset-NanumGothicBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('/fonts/subset-NeueMontreal-LightItalic.woff2') format('woff2'),
      url('/fonts/subset-NeueMontreal-LightItalic.woff') format('woff'),
      url('/fonts/subset-NeueMontreal-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('/fonts/subset-NeueMontreal-Italic.woff2') format('woff2'),
      url('/fonts/subset-NeueMontreal-Italic.woff') format('woff'),
      url('/fonts/subset-NeueMontreal-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('/fonts/subset-NeueMontreal-Bold.woff2') format('woff2'),
      url('/fonts/subset-NeueMontreal-Bold.woff') format('woff'),
      url('/fonts/subset-NeueMontreal-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('/fonts/subset-NeueMontreal-Regular.woff2') format('woff2'),
      url('/fonts/subset-NeueMontreal-Regular.woff') format('woff'),
      url('/fonts/subset-NeueMontreal-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('/fonts/subset-NeueMontreal-BoldItalic.woff2') format('woff2'),
      url('/fonts/subset-NeueMontreal-BoldItalic.woff') format('woff'),
      url('/fonts/subset-NeueMontreal-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Neue Montreal';
  src: url('/fonts/subset-NeueMontreal-Light.woff2') format('woff2'),
      url('/fonts/subset-NeueMontreal-Light.woff') format('woff'),
      url('/fonts/subset-NeueMontreal-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HK Grotesk Pro';
  src: url('/fonts/subset-HKGroteskPro-Black.woff2') format('woff2'),
      url('/fonts/subset-HKGroteskPro-Black.woff') format('woff'),
      url('/fonts/subset-HKGroteskPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HK Grotesk Pro';
  src: url('/fonts/subset-HKGroteskPro-Regular.woff2') format('woff2'),
      url('/fonts/subset-HKGroteskPro-Regular.woff') format('woff'),
      url('/fonts/subset-HKGroteskPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HK Grotesk Pro';
  src: url('/fonts/subset-HKGroteskPro-SemiBold.woff2') format('woff2'),
      url('/fonts/subset-HKGroteskPro-SemiBold.woff') format('woff'),
      url('/fonts/subset-HKGroteskPro-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HK Grotesk Pro';
  src: url('/fonts/subset-HKGroteskPro-Bold.woff2') format('woff2'),
      url('/fonts/subset-HKGroteskPro-Bold.woff') format('woff'),
      url('/fonts/subset-HKGroteskPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HK Grotesk Pro';
  src: url('/fonts/subset-HKGroteskPro-Light.woff2') format('woff2'),
      url('/fonts/subset-HKGroteskPro-Light.woff') format('woff'),
      url('/fonts/subset-HKGroteskPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HK Grotesk Pro';
  src: url('/fonts/subset-HKGroteskPro-Medium.woff2') format('woff2'),
      url('/fonts/subset-HKGroteskPro-Medium.woff') format('woff'),
      url('/fonts/subset-HKGroteskPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

