.textKR {
  width: auto;
  user-select: none;
  font-family: NanumGothic;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: -0.02em;
}
.textKR .text {
  position: relative;
  display: inline-block;
  color: transparent;
}
.textKR .text2 {
  will-change: transform, opacity;
  position: absolute;
  color: #000;
  top: 0;
  left: 0;
  z-index: 2;
}
.textKR h4 {
  position: absolute;
  width: 100%;
  top: -65px;
  left: 0;
  z-index: 0;
  will-change: transform, opacity;
  user-select: none;
  color: transparent;
  font-size: 35px;
  font-weight: 500;
  background: linear-gradient(232.78deg, #ff5379 30.84%, rgba(255,83,121,0.5) 76.68%);
  background-clip: text;
  text-align: center;
}
/*# sourceMappingURL=src/components/TextKR.css.map */