.back {
  color: #fff;
  opacity: 0;
  will-change: opacity;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
  user-select: none;
}
.back img {
  width: 32px;
  height: 32px;
  display: inline-block;
  vertical-align: middle;
  will-change: opacity;
}
/*# sourceMappingURL=src/components/BackButton.css.map */