@import "./assets/css/fonts.css";
body,
html,
#app {
  height: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
html {
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  touch-action: none;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: #fff;
}
html .sign {
  background: url("/img/blur.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  height: 100%;
  min-height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
html .sign img {
  max-width: calc(100% - 80px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  height: auto;
}
html video {
  object-fit: contain;
  max-height: 100%;
  width: auto;
}
html .center {
  height: 100%;
  min-height: 100%;
  width: 100%;
}
/*# sourceMappingURL=src/App.css.map */